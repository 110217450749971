import React from "react"
import { Element } from "react-scroll"
import { Modal, Button } from "antd"
import Img from "gatsby-image"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ExternalLink from "../components/external-link"
// import MailChimpSignUp from "../components/mailchimp-sign-up"

const candidates = [
  {
    name: "Carlos C",
    school: "Cal Poly Pomona",
    position: "Co-Chair",
    bio: (
      <>
        <p>
          My political journey began early, deeply influenced by my family's
          background and experiences as Mexican immigrants who found economic
          security in the United States through well-paying union jobs. It is
          not an exaggeration to say that I am the son and grandson of labor
          unions. My politics are rooted in labor unionism and internationalism,
          guided by Marxist analysis and a critique of capitalism. Growing up, I
          was surrounded by the teachings of Catholic liberation theology and
          the legacy of the Chicano Rights Movement, in which my grandparents
          participated. After the 2016 election, I started my activism by
          working with my grandfather, a well-known Chicano civil rights
          organizer and artist, and his comrades on various political issues and
          campaigns, ranging from immigration rights to community mutual aid.
          These veteran organizers imparted practical skills that I later
          applied to my organizing efforts with Y/DSA.
        </p>
        <p>
          In 2020, alongside my friend Thomas Malone, I co-founded Bronco YDSA
          at California State Polytechnic University, Pomona, during the height
          of the pandemic. With support from then YDSA-LA coordinator Michael
          Stenovec, our chapter gained momentum. Over time, Bronco YDSA built a
          progressive coalition at Cal Poly Pomona, organizing a community brake
          light clinic, supporting unionized undergraduate student workers, and
          collaborating with local groups to pass emergency rent control in
          Pomona. Bronco YDSA emerged as de facto student leaders during the
          faculty and Teamsters strikes across the CSU system. We were also the
          first to protest the military-industrial complex's influence at our
          school by attempting to remove Lockheed Martin from our campus weeks
          before October 7th.
        </p>
        <p>
          Throughout 2022-2023, I served as the deputy YDSA-LA coordinator under
          Abdullah Farooq, the YDSA-LA coordinator. Together, we worked to unify
          Los Angeles YDSA chapters and cultivate a new generation of diverse
          young socialist organizers in Los Angeles. Encouraged by many, I ran
          for and served on the YDSA National Coordinating Committee (NCC) for
          the 2023-2024 term. This role was a valuable learning experience. My
          primary work on the NCC included leading our national solidarity
          campaign with Starbucks Workers United and strengthening California
          YDSA. Amidst the turbulent times at the UC and CSU systems, which
          included unionizing undergraduate student workers, numerous campus
          worker strikes, anti-tuition hike campaigns, and pro-peace
          encampments, it was an honor to collaborate with so many inspiring
          young organizers.
        </p>
        <p>
          My goal is to continue mentoring young, diverse leftists in their
          respective struggles, particularly at public universities and
          community colleges, to ensure YDSA becomes an organization truly
          representative of the multi-racial working class.
        </p>
      </>
    ),
  },
  {
    name: "Uma C",
    // pronouns: "He/Him",
    school: "UCSB",
    position: "Co-Chair",
    bio: (
      <>
        <p>
          I come from a long line of very angry women – some of my first and
          fondest memories of my mother are of her venting about Bush Jr to me
          in our kitchen, or cursing the decision of the Citizens United case
          with her friends on the phone. But my political awakening began in
          middle school, when I learned that Barack Obama was complicit in a
          drone strike that turned a Yemeni wedding into a funeral. When I asked
          my mother why anyone voted for him, I was met with an offensively lame
          lesser-evil explanation and I just became angrier.
        </p>
        <p>
          I stewed in my anger until I arrived to UCSB and stumbled upon YDSA.
          My first exposure to class politics hit me like a train – suddenly
          every struggle was contextualized in a historical tug-of-war, every
          conspiracy was debunked and all truths were laid bare. But the best
          realization I made was that it’s not enough to be angry; YDSA must act
          as a school for teaching the tools of self-emancipation. Every
          campaign we do is a opportunity for young socialists to gain
          experience in confronting the ruling class. In my time at UCSB, I’ve
          helped sustain the second-largest YDSA chapter through one-to-one
          organizing. We’ve won the continuation of on-campus healthcare and a
          meal swipe rollover program. I’ve been a founding organizer in the
          SALU–UAW campaign to organize all student workers at UCSB, and helped
          organize two historic strikes. My ultimate takeaway from these
          experiences is that we cannot be afraid of power. All the niceties and
          distractions of civil society crumble when everyday students are
          called into struggle, and YDSA can inspire this on a massive scale. As
          an NCC member, my top priority would be one-to-one mentorship and
          political education for members of growing chapters, helping students
          articulate socialist politics confidently. I would fight to orient our
          Palestine work toward organizing the unorganized and a culture of
          democracy, to bring university admin to their knees. Further, I want
          to continue to support chapters taking on unionization campaigns and
          building the rank-and-file pipeline to spur bottom-up organizing in
          strategic sectors of the economy. All of these visions are only
          possible because of the discipline and commitment of many organizers
          before us. So it is our job to continue this struggle on our campuses
          and communities, without fear, toward a new world.
        </p>
      </>
    ),
  },
  {
    name: "Daniil S",
    // pronouns: "He/Him",
    school: "Hunter College",
    position: "At-Large",
    bio: (
      <>
        <p>
          I was born in Moscow, Russia. The revolutionary fervor and socialist
          history run through my blood. My family and I moved to New York in
          2015 as the political crises at home escalated and repressions
          tightened. As an immigrant, I was exposed to the reality that stories
          of the American Dream were just an illusion. I saw my family and
          friends struggle to live paycheck to paycheck, unable to afford
          healthcare, and in some instances living in fear of violent
          deportations. The realities of American life pushed me towards
          socialist politics, where I learned how U.S. imperialism destabilized
          hundreds of countries and ripped me from my home.
        </p>
        <p>
          I joined my YDSA chapter at Hunter College during my first year on
          campus. Immediately we began organizing against austerity with the New
          Deal 4 CUNY campaign. Through it, we were able to build one of the
          strongest and most diverse YDSA chapters in the country. As well as
          create a robust and dynamic network of YDSA chapters citywide. In a
          year, I became Co-Chair of NYC-YDSA at the same time as I was a YDSA
          representative to the NYC-DSA Steering Committee - the largest DSA
          chapter in the country. Such a dynamic, lively, and developed network
          of leaders in NYC YDSA allowed us to immediately take action and
          mobilize in the wake of October 7th and what later escalated into a
          full genocide of Palestinian people. We were organizing on our
          campuses and mobilizing in the streets, and when the moment of the
          Encampment Movement came to NYC - we were ready! At Columbia, NYU, and
          CUNY, I was proud to stand shoulder-to-shoulder with my comrades, and
          giving a teach-in on Peter Camejo’s speech at CCNY was an honor of a
          lifetime.
        </p>
        <p>
          My experiences as an immigrant living in America guided me to a
          fundamental belief that as socialists and anti-imperialists in the
          U.S., we have a unique moral duty to build a powerful working-class
          Anti-War movement. A movement that will slit the “Belly of the Beast”
          and rips the cogs off of the murderous war machine. I am running for
          the NCC because I believe that YDSA is a crucial part of a larger
          socialist movement that helps develop young leaders into life-long
          socialist organizers in labor and social movements. Because of YDSA, I
          was inspired to become a teacher in the future, and currently, I am a
          Starbucks worker who has been organizing my shop for over 2 years and
          we will be heading into the election this July. In times of war and
          genocide our presence is ever crucial and the perspectives of people
          like myself, who were ripped from their homelands, are critical in
          these times. I wish to build that movement for peace and socialism,
          alongside all of you.
        </p>
      </>
    ),
  },
  {
    name: "Jeffrey C",
    school: "Oakland University",
    position: "At-Large",
    bio: (
      <>
        <p>
          Living in the Rust Belt for my entire life, I’ve been surrounded by
          Midwestern communities, left in shambles and without opportunity by
          capital. In my youth, volunteering at my church taught me a principle
          that I carry and use to evaluate the world around me: the only option
          is action. Where I lived, I would hear that “lazy” members of our
          society were the problem, but being in a community with those weary of
          Milwaukee, that were gulled by industries promising endless jobs, I
          found the same sentiment kept popping up. They would always say we
          were saving their lives, keeping them going, and giving them hope. As
          a young teen, I figured, if it wasn’t me giving them a plate, someone
          else would I learned that just wasn’t true. It is only a working-class
          movement that can protect our communities from capitalist exploitation
          and violence. As I live on, I am constantly vindicated by that idea.
          It doesn’t matter how knowledgeable you are in the game, if you stand
          on the sidelines you’ll never win, and I’m happy to be a starter.
        </p>
        <p>
          I joined YDSA in 2022 out of pure curiosity, but it became a political
          home for me right away. I quickly got plugged into our Raise the Wage
          Campaign and became ingrained in leadership. Learning the basics of
          labor solidarity organizing, building connections with workers,
          setting up a petition, tabling, flyering, and building pressure.
          During our campaign, I faced confrontations with an organizer’s #1
          enemy: , apathy. Despite constant pushback, after a year of pressure
          and protest, we succeeded. The admin brought the minimum wage on
          campus to $15 an hour, up from $10 an hour, solely due to our work. We
          won not by making deals behind closed doors, but by stepping on toes,
          and pushing for change as a student body. Seeing that work transform
          directly into material gains changed my life, and really solidified my
          belief in the feasibility of bringing about a better world.
        </p>
        <p>
          During my time as Chair of Oakland University YDSA, I’ve taken strides
          to develop my membership, build up knowledgeable leaders, and
          continuously push for better labor conditions for students. In the
          wake of the wage increase this past fall, we lept to involve ourselves
          in the UAW strikes and support our local auto workers around Metro
          Detroit. As the BDS movement grew, we became more committed to
          educating comrades and collaborating with organizations around campus
          to push back against an administration that openly expressed its
          support of Israel. Here especially, I saw the interconnectedness of
          Labor and BDS. We got support from union educators long before the
          administration paid lip service to the victims of genocide in Gaza,
          and I plan to work tirelessly to practice and promote ideas of
          interconnected struggle. Next year, I hope to bring my experience and
          vision to the NCC, where we can build a national student movement for
          Palestine and grow YDSA chapters to take on these fights.
        </p>
      </>
    ),
  },
]

class Candidates2023 extends React.Component {
  state = { visible: false, candidate: null, groupedCandidates: [] }

  componentDidMount() {
    this.setState({
      groupedCandidates: this.getGroupedCandidates(),
    })
  }

  showModal = candidate => {
    this.setState({
      visible: true,
      candidate: candidate,
    })
  }

  handleCancel = e => {
    this.setState({
      visible: false,
    })
  }

  images = () => this.props.data.allFile.edges.map(edge => edge.node)

  image = candidate => {
    const name = candidate.name
      .split(" ")
      .join("-")
      .toLowerCase()
      .replace(/\./g, "")
    return this.images().find(image => image.name === name).childImageSharp
      .fluid
  }

  getGroupedCandidates = () => {
    const positions = []
    const groups = []
    candidates.forEach(candidate => {
      if (!positions.includes(candidate.position)) {
        positions.push(candidate.position)
        groups.push({ position: candidate.position, candidates: [] })
      }
      groups[groups.length - 1].candidates.push(candidate)
    })

    return groups
  }

  render() {
    return (
      <Layout
        title="2024 YDSA Summer Convention"
        color="red"
        heroImageName="index"
        layoutClassName="home"
      >
        <SEO
          title="2024 YDSA Summer Convention Slate &amp; Resolutions"
          ogImage="home"
        />

        <div className="content-column content-column-candidates">
          <Element name="candidates" id="candidates">
            <h2>Meet the Candidates</h2>
          </Element>

          <div className="candidate-cards">
            {this.state.groupedCandidates.map(group => (
              <div className="position" key={group.position}>
                <h3>{group.position}</h3>
                {group.candidates.map((candidate, index) => (
                  <div
                    className="candidate-card"
                    onClick={() => this.showModal(candidate)}
                    onKeyPress={() => this.showModal(candidate)}
                    key={candidate.name}
                    role="button"
                    tabIndex={index}
                  >
                    <CandidatePhoto
                      img={this.image(candidate)}
                      alt={candidate.name}
                    />
                    <div className="candidate-card__content">
                      <h3>{candidate.name}</h3>
                      <h4>{candidate.school}</h4>
                      <Button>Read more&hellip;</Button>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>

        <div className="content-column">
          <h3>
            The 2024 YDSA Convention will be held online from{" "}
            <strong>Friday, July 19 to Sunday, July 21</strong>. For more
            information, please check{" "}
            <ExternalLink href="https://y.dsausa.org/2024convention/">
              official YDSA convention website
            </ExternalLink>
            .
          </h3>
          <p>
            The Bread & Roses caucus is excited to introduce our slate for the
            2024 National YDSA convention: Carlos C (Cal Poly Pomona) and Uma C
            (UC Santa Barbara) for National Co-Chairs, and Daniil S (Hunter
            College) and Jeffrey C (Oakland University) for at-large NCC seats.
            Sign on to support Bread & Roses’ vision for YDSA{" "}
            <ExternalLink href="https://docs.google.com/forms/d/e/1FAIpQLSekXOrpEBaUdC2BcvQZ_9iqtVoetHFecfkG3zUFL6hLfGRo-w/viewform">
              here
            </ExternalLink>
            .
          </p>
          <h2>The Political Moment</h2>
          <p>
            The crises of capitalism can be felt across the United States and
            around the world. Working people are facing the brunt of corporate
            greed as inflation and skyrocketing rent eat away at our paychecks.
            Instead of investing in our campuses and communities, our tax
            dollars and tuition are being used to fund the military-industrial
            complex, funneling money into the genocide in Gaza, war abroad, and
            building cop cities at home. In Gaza, the US war machine funds
            Israel as it wages genocide against the Palestinian people; abroad,
            the working class in Ukraine, Russia, and countries around the world
            are caught in the war of the rich; at home, we experience the brutal
            violence of the police who oppress us for protesting a genocide. The
            Democratic Party has shown their true colors as a tool of the
            rulling-class and US empire: funding Israel’s genocide in Gaza,
            enacting austerity budgets, failing to cancel student debt and
            protect abortion rights, passing inhumane and abusive immigration
            policies, and calling in the police to repress students. The
            resurgence of the far-right and conservative politics can be seen
            worldwide, as the capitalist class use xenophobia and nationalism to
            redirect the anger of the working-class away from themselves and
            towards each other. Younger people, sick of politics as usual,
            increasingly turn away from the two-parties of the ruling-class.
          </p>
          <p>
            Despite all of this, there are reasons to be hopeful for the future.
            The labor movement continues its historic resurgence, as workers
            continue to form unions in droves and leaders like Shawn Fain push
            their unions to wage class war on the shop floor. This resurgence
            was encouraged by the efforts of socialists and labor militants
            organizing within their unions for decades, and it has made its way
            to the campuses where undergraduate student workers continue to
            organize unions and win historic contracts. We’ve also seen the
            historic Palestine solidarity encampment movement rock the world,
            with YDSA chapters playing a leading role on many campuses. These
            actions brought tens of thousands of students from the sidelines
            into the struggle and gave us invaluable experience with mass
            action, new organizing tactics, and showed the importance of
            democracy and flexibility in our organizing. We must share our
            experiences and analyses of this moment, the largest student
            movement of the twenty-first century, to move forward as a stronger
            and more committed YDSA.
          </p>
          <h3>How do we meet the moment?</h3>
          <p>
            There’s only one way forward for the socialist movement: building
            long-term power through mass action. Millions of young people across
            the United States are being radicalized as they watch their tax
            dollars fund a genocide while their schools crumble and their
            communities are threatened by climate change. The only way to beat
            the ruling class, and its trillions of dollars defended by the armed
            wing of the state, is through our numbers and organizing ourselves
            as workers. We must meet the movement where it is at. That means
            injecting dynamism into social movements, like the movement for a
            free Palestine, experimenting with new outward-facing tactics and
            strategies to push millions of people into the streets and the
            struggle. That means recruiting and developing thousands of new YDSA
            members across the country from the recently politicized masses.
            That means fighting for democratic practices to facilitate the
            development of flexible, sustainable, and engaging movements and
            movement organizations to make our organizing more successful and
            beat the administrators and capitalists who are content to wait for
            our organization to crumble.
          </p>
          <p>
            Engaging in movements alone is not enough. We need to build power
            that will last beyond a campaign and beyond the four years that many
            of us spend on our campuses. We need to organize student worker
            unions amongst our coworkers and colleagues to withhold our labor
            and demand meaningful change from administration. We should also
            take inspiration from the student unions in Brazil and other parts
            of the world to create the infrastructure for student strikes and
            other mass action on campus. By organizing student unions and
            student-worker unions, we can leverage our structural power as
            students and workers and force our administrators to concede to our
            demands.
          </p>
          <p>
            YDSA’s goal is not just to win power in the here and now but to
            change the world. YDSA plays a special role as a socialist
            “boot-camp:” through engaging in class-struggle and building mass
            organizations on our campuses, we are able to develop generations of
            life-long socialist organizers. By encouraging young socialists to
            take strategic rank-and-file jobs after graduation, YDSA can
            reinvigorate the labor movement from the bottom-up and build a base
            for a future worker’s party.
          </p>
          <h3>Empowering YDSA to Meet the Moment</h3>
          <p>
            We know that we have to help develop democratic, militant, dynamic
            social movements and organize student unions and student worker
            unions on our campuses. But the methods we use to achieve these
            goals will vary based on geographic region, campus conditions, and
            the broader political moment. YDSA, therefore, must experiment with
            new tactics, strategies, and structures to meet its goals. We also
            must be flexible and adaptable to respond to crucial moments, like
            the wave of Gaza Solidarity Encampments this Spring. To be able to
            experiment and be flexible, we must continue to advocate for YDSA
            autonomy.
          </p>
          <p>
            That is the ability of YDSA, through its Convention and elected
            leadership , to make our own decisions and direct our own
            organizing. Having our own independent member-led Comms committee
            helped YDSA meet the political moment during the encampment upsurge.
            To continue to meet the moment, we must further commit to YDSA
            autonomy. This means giving YDSA control over its own budget and
            fundraising efforts so that we can adjust our resources based on
            what is most strategic as organizing conditions change. It also
            means giving YDSA control over our international organizing so that
            we can more efficiently and effectively connect with new
            organizations around the world and share tactics and experiences.
            YDSA has grown to its current size and scale through our dynamic and
            exciting organizing on our campuses. As we find ourselves in another
            upsurge in campus organizing, we must continue to focus our efforts
            outwards, not inwards, to continue YDSA’s exponential growth and be
            ready to meet the next moment that comes our way.
          </p>
          <h3 style={{ textAlign: "center" }}>
            Do you support Bread and Roses' vision for YDSA?{" "}
            <ExternalLink href="https://docs.google.com/forms/d/e/1FAIpQLSekXOrpEBaUdC2BcvQZ_9iqtVoetHFecfkG3zUFL6hLfGRo-w/viewform">
              Sign here!
            </ExternalLink>
          </h3>
          {/* <h3 style={{ textAlign: "center" }}>
            Find our official voting guide at:{" "}
            <ExternalLink href="https://bit.ly/breadandroses-2022">
              https://bit.ly/breadandroses-2022
            </ExternalLink>
          </h3> */}
        </div>

        <Modal
          visible={this.state.visible}
          onCancel={this.handleCancel}
          footer={null}
          width={600}
        >
          {this.state.candidate && (
            <>
              <h2>
                {this.state.candidate.name}, {this.state.candidate.school}
              </h2>
              <h3>
                {/* {this.state.candidate.pronouns},  */}
                {/* {this.state.candidate.position} */}
              </h3>
              <CandidatePhoto
                img={this.image(this.state.candidate)}
                alt={this.state.candidate.name}
              />
              {this.state.candidate.bio}
            </>
          )}
        </Modal>
      </Layout>
    )
  }
}

const CandidatePhoto = ({ img, alt }) => (
  <Img className="candidate-photo" fluid={img} alt={alt} />
)

export const query = graphql`
  query Convention2020HeadshotsQuery {
    allFile(filter: { absolutePath: { glob: "**/headshots/ydsa-2024/*" } }) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 150, maxHeight: 150) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
    }
  }
`

export default Candidates2023
